import { apiCall } from '../index';
import ASubAdmin from '../../models/Admin/ASubAdmin';
import store from '../../store';

import {
	setIsLoading,
	setErrorTrigger,
	setList,
	handleCreate,
	handleUpdate,
	handleDelete,
	setIsLoadingRefreshCompanies,
} from '../../store/slices/aSyncUsers.slice';
import ASyncUser from '../../models/Admin/ASyncUser';
import { getServers } from 'api-services/server';
import AServer from 'models/Admin/AServer';

type CreateBody = {
	serverId: string;
	companyIds: string[] | null;
	username: string;
	password: string;
};

type UpdateBody = {
	serverId: string;
	companyIds: string[] | null;
	username: string;
	password: string | null;
};

type UpdateCompaniesBody = {
	companyIds: string[];
	serverId: number;
};

type RefreshCompaniesBody = {
	serverId: number;
};

export async function getSyncUsers() {
	store.dispatch(setIsLoading(true));

	const res = await apiCall<ASyncUser[]>('admin/sync-users');
	store.dispatch(setIsLoading(false));
	if (res.status !== 200) {
		store.dispatch(setErrorTrigger(res));
		return;
	}
	store.dispatch(setList(res.data!));
}

export async function createSyncUser(body: CreateBody) {
	store.dispatch(setIsLoading(true));

	console.log('body : ', body);

	const res = await apiCall<ASyncUser>('admin/sync-users', true, body, 'post');
	store.dispatch(setIsLoading(false));
	if (res.status !== 200) {
		store.dispatch(setErrorTrigger(res));
		return;
	}
	store.dispatch(handleCreate(res.data!));
}

export async function updateSyncUser(id: string, body: UpdateBody) {
	store.dispatch(setIsLoading(true));

	const res = await apiCall<ASyncUser>(`admin/sync-users/update/${id}`, true, body, 'put');
	store.dispatch(setIsLoading(false));
	if (res.status !== 200) {
		store.dispatch(setErrorTrigger(res));
		return;
	}
	store.dispatch(handleUpdate(res.data!));
}

export async function updateSyncUserCompanies(id: string, body: UpdateCompaniesBody) {
	store.dispatch(setIsLoading(true));

	const res = await apiCall<ASyncUser>(`admin/sync-users/update-companies/${id}`, true, body, 'put');
	store.dispatch(setIsLoading(false));
	if (res.status !== 200) {
		store.dispatch(setErrorTrigger(res));
		return;
	}
	store.dispatch(handleUpdate(res.data!));
}

export async function deleteSyncUser(
	id: string,
	body: {
		serverId: number;
	}
) {
	store.dispatch(setIsLoading(true));

	const res = await apiCall<{
		status: 200;
		message: 'ok';
	}>(`admin/sync-users/${id}`, true, body, 'delete');
	store.dispatch(setIsLoading(false));
	if (res.status !== 200) {
		store.dispatch(setErrorTrigger(res));
		return;
	}
	store.dispatch(
		handleDelete({
			id: id,
			serverId: body.serverId.toString(),
		})
	);
}

export async function refreshCompanies(id: string, body: RefreshCompaniesBody) {
	// store.dispatch(setIsLoadingRefreshCompanies(true));

	const res = await apiCall<ASyncUser>(`admin/sync-users/refresh-companies/${id}`, true, body, 'post');
	// store.dispatch(setIsLoadingRefreshCompanies(false));
	if (res.status !== 200) {
		store.dispatch(setErrorTrigger(res));
		return;
	}
	store.dispatch(handleUpdate(res.data!));
	await getServers();
}


export async function checkSync(id: string, body: RefreshCompaniesBody) {

	const res = await apiCall<AServer>(`admin/sync-users/check-sync/${id}`, true, body, 'post');

	return res.data?.isSyncing;
}

export async function syncCompanies(id: string, body: RefreshCompaniesBody) {
	// store.dispatch(setIsLoading(true));

	const res = await apiCall<ASyncUser>(`admin/sync-users/sync/${id}`, true, body, 'post');
	// store.dispatch(setIsLoading(false));
	if (res.status !== 200) {
		store.dispatch(setErrorTrigger(res));
		return;
	}
	await getServers();
	store.dispatch(handleUpdate(res.data!));
}
