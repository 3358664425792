import { createSlice } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import Order, { OrderPhase } from 'models/Order';
import ErrorResponse from 'network/responses/ErrorResponse';
import UnassignOrderRequest from '../../network/requests/UnassignOrderRequest';
import OkResponse from '../../network/responses/OkResponse';
import PaginatedResponse from '../../network/responses/PaginatedResponse';
import authenticationAsyncActions from '../actions/authentication.action';
import ordersAsyncActions from '../actions/orders.action';
import postErrorRequest from '../postErrorRequest';
import postRequest from '../postRequest';
import { CPA, OrdersState, PA, Timing } from '../types';

const initialState: OrdersState = {
	hasMorePages: true,
	isPending: false,
	isLoading: false,
	list: [],
	updatedAt: Timing.now(),
	refresh: 0,
};

const applySort = (state: WritableDraft<typeof initialState>) => {
	state.list.sort((a, b) => {
		if (a.orderNo < b.orderNo) {
			return -1;
		}
		if (a.orderNo > b.orderNo) {
			return 1;
		}
		return 0;
	});
	state.list.sort((a) => {
		if (a.isManualRemoved) {
			return -1;
		}
		return 0;
	});
	state.list.sort((a) => {
		if (a.isIncompleted) {
			return -1;
		}
		return 0;
	});
	state.updatedAt = Timing.now();
};

const slice = createSlice({
	name: 'orders',
	initialState,
	reducers: {
		setIsLoading: (
			state,
			{
				payload,
			}: {
				payload: boolean;
			}
		) => {
			state.isLoading = payload;
		},
		refresh: (state: WritableDraft<typeof initialState>) => {
			state.refresh = Timing.now();
		},
		updatePhase: (
			state: WritableDraft<typeof initialState>,
			action: PA<{ orderId: string; phase: OrderPhase }>
		) => {
			const findIndex = state.list.findIndex((element) => element.id === action.payload.orderId);
			if (findIndex !== -1) {
				state.list.splice(findIndex, 1, {
					...state.list[findIndex],
					phase: action.payload.phase,
				});
				state.updatedAt = Timing.now();
			}
		},
		scheduleByIds: (state: WritableDraft<typeof initialState>, { payload }: PA<string[]>) => {
			console.log('state list: ', state.list[0].id);
			for (const id of payload) {
				const findIndex = state.list.findIndex((element) => element.id === id);
				console.log('findIndex value: ', findIndex);
				if (findIndex !== -1) {
					const newOrder = { ...state.list[findIndex] };

					newOrder.isScheduled = true;

					state.list.splice(findIndex, 1, newOrder);
				}
			}
			console.log('state list after: ', state.list[0].id);
			state.updatedAt = Timing.now();
		},
		unScheduleByIds: (state: WritableDraft<typeof initialState>, { payload }: PA<string[]>) => {
			for (const id of payload) {
				const findIndex = state.list.findIndex((element) => element.id === id);
				if (findIndex !== -1) {
					const newOrder = { ...state.list[findIndex] };

					newOrder.isScheduled = false;

					state.list.splice(findIndex, 1, newOrder);
				}
			}
			state.updatedAt = Timing.now();
		},
		unassignOrder: (
			state: WritableDraft<typeof initialState>,
			action: PA<Pick<UnassignOrderRequest, 'orderId'>>
		) => {
			const findIndex = state.list.findIndex((element) => element.id === action.payload.orderId);
			if (findIndex !== -1) {
				state.list.splice(findIndex, 1, {
					...state.list[findIndex],
					isManualRemoved: true,
				});
				applySort(state);
			}
		},
		sort: (state: WritableDraft<typeof initialState>) => {
			applySort(state);
		},
		clear: () => initialState,
	},
	extraReducers: {
		[ordersAsyncActions.index.pending.type]: (state) => {
			state.isPending = true;
		},
		[ordersAsyncActions.index.fulfilled.type]: (state, action: CPA<PaginatedResponse<Order>>) => {
			state.hasMorePages = action.payload.hasMorePages;
			state.isPending = false;
			for (const order of action.payload.data) {
				const findIndex = state.list.findIndex((element) => element.id === order.id);
				if (findIndex === -1) {
					state.list.push(order);
				} else {
					state.list.splice(findIndex, 1, order);
				}
			}
			applySort(state);

			postRequest(action);
		},
		[ordersAsyncActions.indexScheduled.fulfilled.type]: (state, action: CPA<Order[]>) => {
			for (const order of action.payload) {
				const findIndex = state.list.findIndex((element) => element.id === order.id);
				if (findIndex === -1) {
					state.list.push(order);
				} else {
					state.list.splice(findIndex, 1, order);
				}
			}
			applySort(state);

			postRequest(action);
		},
		[ordersAsyncActions.indexByIds.fulfilled.type]: (state, action: CPA<Order[]>) => {
			state.isLoading = false;
			state.isPending = false;
			for (const order of action.payload) {
				const findIndex = state.list.findIndex((element) => element.id === order.id);
				if (findIndex === -1) {
					state.list.push(order);
				} else {
					state.list.splice(findIndex, 1, order);
				}
			}
			applySort(state);

			postRequest(action);
		},
		[ordersAsyncActions.addItem.fulfilled.type]: (state, action: CPA<Order>) => {
			const findIndex = state.list.findIndex((element) => element.id === action.payload.id);
			if (findIndex !== -1) {
				state.list.splice(findIndex, 1, action.payload);
			} else {
				state.list.push(action.payload);
			}
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[ordersAsyncActions.deleteItem.fulfilled.type]: (state, action: CPA<Order>) => {
			const findIndex = state.list.findIndex((element) => element.id === action.payload.id);
			if (findIndex !== -1) {
				state.list.splice(findIndex, 1, action.payload);
			} else {
				state.list.push(action.payload);
			}
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[ordersAsyncActions.update.fulfilled.type]: (state, action: CPA<Order>) => {
			const findIndex = state.list.findIndex((element) => element.id === action.payload.id);
			if (findIndex !== -1) {
				state.list.splice(findIndex, 1, action.payload);
			} else {
				state.list.push(action.payload);
			}
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[ordersAsyncActions.sync.fulfilled.type]: (state, action: CPA<Order[]>) => {
			state.list = action.payload;
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[ordersAsyncActions.override.fulfilled.type]: (state, action: CPA<OkResponse>) => {
			postRequest(action);
		},
		[ordersAsyncActions.index.rejected.type]: (state, action: CPA<ErrorResponse>) => {
			state.isPending = false;

			return postErrorRequest(state, action, initialState);
		},
		[ordersAsyncActions.indexScheduled.rejected.type]: (state, action: CPA<ErrorResponse>) => {
			return postErrorRequest(state, action, initialState);
		},
		[ordersAsyncActions.indexByIds.rejected.type]: (state, action: CPA<ErrorResponse>) => {
			state.isLoading = false;
			state.isPending = false;

			return postErrorRequest(state, action, initialState);
		},
		[ordersAsyncActions.addItem.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[ordersAsyncActions.deleteItem.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[ordersAsyncActions.update.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[ordersAsyncActions.sync.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[ordersAsyncActions.override.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[authenticationAsyncActions.signIn.fulfilled.type]: () => initialState,
		[authenticationAsyncActions.signOut.fulfilled.type]: () => initialState,
	},
});

export const ordersActions = slice.actions;

export default slice.reducer;
